const width  = $(window).width();

const slider = [];

// Bootstrap datepicker options
const datepickerOptions = {
    format: 'mm/dd/yyyy',
    container: '.form-group-date-wrapper',
    autoclose: true,
    templates: {
          leftArrow: '<i class="mdi mdi-chevron-left"></i>',
          rightArrow: '<i class="mdi mdi-chevron-right"></i>'
    }
}
// Home page (Main slider) config
if($('.bxslider').length) {
    $('.bxslider').bxSlider({
        auto: true,
        controls: false,
        onSliderLoad: function(){
            $('.bxslider').removeClass('bxslider-loading')
        },
    });
}

// Flights carousel config
if($('.flight-carousel').length) {
    $('.flight-carousel').bxSlider({
        // auto: true,
        // autoHover: true,
        minSlides: width > 767 ? 1 : 4,
        maxSlides: 15,
        slideMargin:  20,
        slideWidth: 280,
        nextSelector: '.flight-control-right',
       	prevSelector: '.flight-control-left',
       	nextText: '',
       	prevText: '',
          infiniteLoop: true,
          onSliderLoad: function(){
              $('.flight-carousel').removeClass('loading')
          },

    });
}


const tariffCarousel = function(el) {
	return $(el).bxSlider({
        // auto: true,
        // autoHover: true,
        minSlides: 1,
        maxSlides: 15,
        slideMargin: 25,
        slideWidth: 255,
        moveSlides : 1,
        nextSelector: $(el).parent().find('.tariff-carousel-pager-right'),
       	prevSelector: $(el).parent().find('.tariff-carousel-pager-left'),
       	nextText: '',
       	prevText: '',
       	infiniteLoop: false,
       	speed: 500,
        onSliderLoad: function(){
        	// alert(1)
            $('.tariff-carousel-ul').removeClass('loading')
        },

    })
}


// Flights carousel config
if($('.tariff-carousel-ul').length) {

	$('.tariff-carousel-ul').each(function(i,el){
		slider[i] = tariffCarousel(el)
	})
}


$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {

    if(!$('.tariff-carousel-ul').length) {
        return false;
    }

  	var target = $(e.target).attr("href")

  	var index = $(target).index();

	  slider[index].destroySlider()

	  var el = $(target).find('.tariff-carousel-ul');

  	slider[index] = tariffCarousel(el)
});



//Google Maps Config
function googleMap(id) {
    if($('#'+id).length > 0) {
        var marker;
        var coordinate = $('#'+id).attr('data-coordinate').split(',')

        function initMap() {
          var map = new google.maps.Map(document.getElementById(id), {
              zoom: 13,
        streetViewControl: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
              center: {lat: parseFloat(coordinate[0]), lng: parseFloat(coordinate[1])}
          });
          marker = new google.maps.Marker({
              map: map,
              draggable: true,
              animation: google.maps.Animation.DROP,
              position: {lat: parseFloat(coordinate[0]), lng: parseFloat(coordinate[1])},
              // icon: {
              //     url: '/css/image/icon/map-marker-big.png',
              //     scaledSize: new google.maps.Size(41,50),
              //     origin: new google.maps.Point(0,0),
              //     anchor: new google.maps.Point(0,0)
              // },
          });
          marker.addListener('click', toggleBounce);
        }
        function toggleBounce() {
            if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
            } else {
            marker.setAnimation(google.maps.Animation.BOUNCE);
            }
        }
        google.maps.event.addDomListener(window, 'load', initMap);
    }
}
googleMap('google-map');


// Bootstrap datepicker

$('.form-group-date input').datepicker(datepickerOptions);

$('.form-datepicker-icon').click(function(){
    $('.form-group-date input').datepicker(datepickerOptions).focus()
})


// Bootstrap popover

$('.popover-el').popover({
    container: 'body'
})


// Colorbox config

if($(document).find('.inline').length)
{
    var colorbox_width = $('.inline')[0].hasAttribute('data-width')? $('.inline').attr('data-width')  : "40%";
    $(".inline").colorbox({
      inline:true, 
      width: colorbox_width,
      close: '<span class="colorbox-close"><i class="mdi mdi-close"></i></span>'
    });
}